import React from "react"
import { graphql, StaticQuery, Link } from "gatsby"
import Img from "gatsby-image"
import Typewriter from "typewriter-effect"
import FormContato from "../components/formContato"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "../utils/normalize.css"
import "../utils/css/screen.css"

const ParaVoce = ({ data }, location) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout title={siteTitle}>
      <SEO title="Cursos" />
      <article className="post-content page-template no-image">
        <div className="post-content-body">
          <header className="post-content-header">
            <h2>
              <Typewriter
                options={{
                  autoStart: true,
                  loop: true,
                  delay: 55,
                }}
                onInit={typewriter => {
                  typewriter
                    .typeString(
                      '<span style="color: #26BBBA">Amplie</span> suas oportunidades!'
                    )
                    .pauseFor(999999)
                    .start()
                }}
              />
            </h2>
          </header>

          <div className="row" style={{ width: `100%` }}>
            <div className="col-6">
              <div
                style={{
                  padding: "1rem 0",
                  textAlign: "center",
                  background: "#ffffff",
                  fontSize: "18px",
                }}
              >
                <a href="#rePense">(re)Pense</a>
              </div>
            </div>
            <div className="col-6">
              <div
                style={{
                  padding: "1rem 0",
                  textAlign: "center",
                  background: "#ffffff",
                  fontSize: "18px",
                }}
              >
                <a href="#formacaoRH">Formação em Recursos Humanos</a>
              </div>
            </div>
          </div>

          <br />
          <br></br>

          <div
            id="rePense"
            className="row transition-fade"
            style={{
              width: `100%`,
              padding: "10px",
              textAlign: "left",
              background: "#ffffff",
              fontSize: "16px",
              verticalAlign: "center",
            }}
          >
            <div className="row col-12">
              <div className="col-4" style={{ textAlign: `center` }}>
                <Img
                  fixed={data.rePense.childImageSharp.fixed}
                  className="kg-image"
                />
              </div>
              <div className="col-8">
                <b style={{ fontSize: `25px`, color: `#26BBBA` }}>RE(PENSE)</b>
                <br />
                <br />
                <p>
                  <b>A quem se destina?</b>
                  <br />
                  Todas as pessoas que querem se abrir para novas perspectivas e
                  entendem que a oportunidade está na habilidade de aprender.
                </p>
              </div>
            </div>

            <div className="row">
              <div className="col-12" style={{ padding: `30px` }}>
                <i>
                  "O analfabeto do século XXI não será aquele que não consegue
                  ler e escrever, mas aquele que não consegue aprender,
                  desaprender, e reaprender." <b> - Alvin Toffler</b>
                </i>
                <br />
                <br />

                <p>
                  - O mundo está de cabeça para baixo e nada funciona.
                  <br />
                  - As oportunidades não são as mesmas como antes.
                  <br />
                  - Esta nova geração não sabe reconhecer os verdadeiros
                  valores.
                  <br />
                  - As pessoas não valorizam o meu talento.
                  <br />
                  - Não tenho mais idade para isso.
                  <br />
                  - Nada dá certo para mim ou é tudo difícil.
                  <br />
                  - Se eu tivesse uma chance.
                  <br />
                  <br />
                  Essas frases são exemplos de pensamentos (consciente ou
                  inconsciente) que podem limitar suas perspectivas e atitudes.
                  <br />
                  <br />
                  A forma como nos relacionamos, interpretamos situações,
                  julgamos a nós mesmos e, consequentemente, tomamos decisões é
                  influenciada por crenças que formamos com base em nossas
                  experiências ao longo da vida.
                  <br />
                  <br />
                  Muitas vezes, decidimos de forma inconsciente e nossas ações
                  são carregadas de vieses de julgamento sobre os outros e sobre
                  nós mesmos.
                  <br />
                  <br />
                  Todos nós interpretamos a realidade pela nossa perspectiva e
                  não há nenhum problema sobre isso, desde que seus pensamentos
                  não te boicotem.
                  <br />
                  <br />O caminho está na flexibilidade em rever conceitos e
                  (re)aprender com a experiência.
                </p>
                <p>
                  <b>Para quê fazer este curso?</b>
                </p>
                <p>
                  <b>Aprender</b> sobre mecanismos de pensamento e como impactam
                  em sua perspectiva e tomada de decisão.
                  <br />
                  <b>Reconhecer</b> seus modelos mentais, evitando que crenças
                  limitantes interfiram em seus projetos / objetivos.
                  <br />
                  <b>Praticar</b> um novo jeito de pensar, exercitando o
                  otimismo, a confiança, identificando talentos naturais e
                  comportamentos para superar desafios.
                  <br />
                  <br />
                  <b>*Curso on line ou presencial.</b>
                </p>
              </div>
            </div>
          </div>

          <br />

          <div
            id="formacaoRH"
            className="row transition-fade"
            style={{
              width: `100%`,
              padding: "10px",
              textAlign: "left",
              background: "#ffffff",
              fontSize: "16px",
              verticalAlign: "center",
            }}
          >
            <div className="row col-12">
              <div className="col-4" style={{ textAlign: `center` }}>
                <Img
                  fixed={data.formacaoRH.childImageSharp.fixed}
                  className="kg-image"
                />
              </div>
              <div className="col-8">
                <b style={{ fontSize: `25px`, color: `#26BBBA` }}>
                  FORMAÇÃO EM RECURSOS HUMANOS <br />
                  RH com Valores 1.0 e Soluções 5.0
                </b>
                <br />
                <br />
                <p>
                  <b>A quem se destina?</b>
                  <br />
                  Profissionais de RH, líderes e empreendedores.
                </p>
              </div>
            </div>
            <br />

            <div className="row">
              <div className="col-12" style={{ padding: `30px` }}>
                <p>
                  <b>Para quê fazer o curso?</b>
                  <br />
                  1. Reconhecer valores fundamentais para o/a profissional de RH
                  e como as habilidades pessoais constroem a credibilidade da
                  área.
                  <br />
                  2. Aprender como os processos da área de RH e gestão de
                  pessoas impactam no fortalecimento das relações humanas e da
                  performance, quando seu design está comprometido com a
                  experiência das pessoas, com o propósito e com o
                  posicionamento estratégico do negócio.
                  <br />
                  3. Entender que o posicionamento estratégico do time de RH
                  está mais no porquê e menos o quê fazer. Um time estratégico
                  de RH opta pelas ações que impactam verdadeiramente pessoas e
                  negócio.
                  <br />
                  4. Compreender que o RH ágil está relacionado à mentalidade, a
                  vontade de resolver problemas e na clareza das relações.
                  <br />
                  5. Aprender sobre design, UX, pensamento escalável e gestão de
                  dados.
                </p>
                <br />

                <p>
                  <b>Etapas do curso:</b>
                  <br />
                  - Mesa redonda: debate sobre as características humanas
                  fundamentais para o/a profissional de RH e a aplicação no dia
                  a dia das organizações.
                  <br />
                  - Propósito e Cultura como pilares da estratégia de RH.
                  <br />
                  - Estratégia com foco na Experiência das Pessoas.
                  <br />
                  - Pensar ágil / Pensar escalável / Soluções baseadas em design
                  e UX.
                  <br />
                  - Qualidade de vida e bem estar nas organizações.
                  <br />
                  - Atração&Seleção.
                  <br />
                  - Onboarding.
                  <br />
                  - Rituais de gestão com escalabilidade.
                  <br />
                  - Educação&Desenvolvimento.
                  <br />
                  - Comunicação&Engajamento.
                  <br />
                  - People Analytics&KPIs.
                  <br />
                  - Gestão da Performance&Carreira.
                  <br />
                  - Gestão de Talentos&Retenção.
                  <br />
                  - Desligamento.
                  <br />- Mentoria para projeto (opcional).
                </p>

                <p>
                  <b>
                    *O curso pode ser presencial ou on line. Os módulos são
                    independentes, podendo ser contratados individualmente.
                  </b>
                </p>
              </div>
            </div>
          </div>

          <br />
          <hr />
          <h3 className="post-content-title">
            Quer saber mais sobre os cursos? Me manda mensagem no formulário
            abaixo:
          </h3>
          <FormContato />
        </div>
      </article>
    </Layout>
  )
}

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }

    rePense: file(relativePath: { eq: "curso-repense.png" }) {
      childImageSharp {
        fixed(height: 200, width: 200) {
          ...GatsbyImageSharpFixed
        }
      }
    }

    formacaoRH: file(relativePath: { eq: "curso-formacaoemrh.png" }) {
      childImageSharp {
        fixed(height: 200, width: 200) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`

export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <ParaVoce location={props.location} data={data} {...props} />
    )}
  />
)
